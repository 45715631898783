<template>
  <div>
    <div class="title">
      <div class="title_1" @click="goback">
        <img src="../../assets/zjt.png" alt="" />
        返回
      </div>
      <div class="title_2">
        {{ $route.query.up }} > {{ $route.query.tabtitle }} > 成绩
      </div>
    </div>
    <div class="grade" v-if="$route.query.type == 1">练习成绩</div>
    <div class="score" v-if="$route.query.type == 1">{{ row.totalscore }}</div>
    <div style="height: 60px" v-else></div>
    <div class="tab">
      <div class="item">
        <div>做题总数</div>
        <div>
          <span class="l1">{{ row.ecount }}</span
          >题
        </div>
      </div>
      <div class="item">
        <div>做题正确数</div>
        <div>
          <sapn class="l2">{{ row.rcount }}</sapn
          >题
        </div>
      </div>
      <div class="item">
        <div>正确率</div>
        <div>
          <span class="l3">{{ row.zql }}</span
          >%
        </div>
      </div>
    </div>
    <div class="rank">您的练习级别</div>
    <div>
      <img
        class="img"
        v-if="row.status == 1"
        src="../../assets/rank1.png"
        alt=""
      />
      <img
        class="img"
        v-if="row.status == 0"
        src="../../assets/rank2.png"
        alt=""
      />
    </div>
    <!-- <recordtime type="4" :record="record"> </recordtime> -->
    <writetime type="4"></writetime>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      num: 0,
      row: {},
      record: 2,
    };
  },
  computed: {
    ...mapState({
      classid: (state) => state.classid,
      userInfo: (state) => state.userInfo,
    }),
  },
  methods: {
    ...mapActions(["getSectionTestScores"]),
    goback() {
      if (this.$route.query.type == 1) {
        this.$router.push("/Layoutstudy/examination");
      } else {
        this.$router.push("/Layoutstudy/writing");
      }
    },
  },
  mounted() {
    //答题完成--提交成绩
    if (this.$route.query.type == 1) {
      this.recordz = 1;
    } else {
      this.recordz = 2;
    }
    this.getSectionTestScores({
      username: this.userInfo.username,
      class: this.classid,
      textid: this.$route.query.textid,
      type: this.$route.query.type,
      zj_id: "",
    }).then((res) => {
      this.row = res.data;
      this.$message({
        message: "答题完成",
        type: "success",
      });
    });
  },
};
</script>

<style lang='scss' scoped>
.title {
  display: flex;
  border-bottom: 1px solid #e1e1e1;
  height: 45px;
  .title_1 {
    cursor: pointer;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #676767;
    img {
      margin-right: 5px;
      height: 20px;
      vertical-align: middle;
    }
  }
  .title_2 {
    margin-left: 24px;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #222222;
  }
}
.grade {
  margin-top: 48px;
  text-align: center;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #7c7c7c;
}
.score {
  text-align: center;
  font-size: 100px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ff604f;
}
.tab {
  padding: 29px 0;
  display: flex;
  box-sizing: border-box;
  margin: 0 79px;
  height: 110px;
  background: #ffffff;
  box-shadow: 0px 0px 8px 3px rgba(0, 110, 207, 0.1);
  border-radius: 20px;
  .item {
    border-right: 1px solid #e5e5e5;
    width: 33.3%;
    text-align: center;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #7c7c7c;
    div {
      margin-bottom: 7px;
    }
    &:nth-child(3) {
      border-right: 0px none;
    }

    .l1 {
      margin-right: 5px;
      color: #147dfb;
    }
    .l2 {
      margin-right: 5px;

      color: #62e3a9;
    }
    .l3 {
      margin-right: 5px;

      color: #ff604f;
    }
  }
}
.rank {
  margin-top: 77px;
  text-align: center;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #7c7c7c;
}
.img {
  display: block;
  margin: 19px auto 500px;
}
</style>